<template>
  <svg :width="size" :height="size" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.2929 19.2929C19.6834 18.9024 20.3166 18.9024 20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071L19.413 22H27C27.5128 22 27.9355 22.386 27.9933 22.8834L28 23C28 23.5523 27.5523 24 27 24H19.415L20.7071 25.2929C21.0676 25.6534 21.0953 26.2206 20.7903 26.6129L20.7071 26.7071C20.3166 27.0976 19.6834 27.0976 19.2929 26.7071L16.2929 23.7071C15.9024 23.3166 15.9024 22.6834 16.2929 22.2929L19.2929 19.2929ZM24 12.8458C24 11.7776 23.9266 11.3973 23.7175 11.0063C23.5492 10.6917 23.3083 10.4508 22.9937 10.2825C22.6027 10.0734 22.2224 10 21.1542 10H4V19.1542C4 20.2224 4.07344 20.6027 4.28251 20.9937C4.45077 21.3083 4.69171 21.5492 5.00633 21.7175C5.39726 21.9266 5.77757 22 6.84583 22H13C13.5523 22 14 22.4477 14 23C14 23.5523 13.5523 24 13 24H6.84583C5.47564 24 4.78342 23.8663 4.06313 23.4811C3.39998 23.1265 2.87355 22.6 2.51889 21.9369C2.13367 21.2166 2 20.5244 2 19.1542V8H2.002L2.00211 7.9453C2.01921 6.94498 2.14224 6.38674 2.4521 5.80734C2.76319 5.22567 3.22567 4.76319 3.80734 4.4521C4.43503 4.11641 5.03787 4 6.20486 4H17.7951C18.9621 4 19.565 4.11641 20.1927 4.4521C20.7743 4.76319 21.2368 5.22567 21.5479 5.80734C21.8654 6.4011 21.9868 6.97263 21.999 8.02047C22.844 8.07473 23.3821 8.22221 23.9369 8.51889C24.6 8.87355 25.1265 9.39997 25.4811 10.0631C25.8663 10.7834 26 11.4756 26 12.8458V19C26 19.5523 25.5523 20 25 20C24.4477 20 24 19.5523 24 19V12.8458ZM20 15C20.5523 15 21 15.4477 21 16C21 16.5523 20.5523 17 20 17H18C17.4477 17 17 16.5523 17 16C17 15.4477 17.4477 15 18 15H20ZM17.7951 6H6.20486C5.3398 6 5.04887 6.05618 4.75054 6.21573C4.5174 6.34041 4.34041 6.5174 4.21573 6.75054C4.06949 7.02398 4.01009 7.29121 4.0012 8H19.9988C19.9899 7.29121 19.9305 7.02398 19.7843 6.75054C19.6596 6.5174 19.4826 6.34041 19.2495 6.21573C18.9511 6.05618 18.6602 6 17.7951 6Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'ReceiveWallet',
  props: {
    size: {
      type: Number,
      default: 28
    }
  }
}
</script>